#views-exposed-form-dkan-datasets-panel-pane-1 {
  .views-exposed-form .views-widget-filter-search_api_views_fulltext {
    max-width: 320px;
  }
}

#dkan-sitewide-dataset-search-form,
#dkan-sitewide-dataset-search-form--2 {
  @include searchbox;
  position: relative;
}
#dkan-sitewide-dataset-search-form,
#dkan-sitewide-dataset-search-form--2 {
  label {
    @extend %screenreader;
  }
}

#region-branding #block-dkan-sitewide-dkan-sitewide-search-bar label {
  @extend %screenreader;
}

#block-dkan-sitewide-dkan-sitewide-search-bar {
  @media all and (min-width:768px) {
    position: absolute;
    top: 10px;
    right: 15px;
    width:172px;
    form {
      width:172px;
    }
    input.form-control {
      width:170px;
      height: 36px;
      margin: 0;
    }
  }
  @include searchbox;
  clear: both;
  .views-exposed-form .form-submit {
    margin:22px 0 0;
    text-shadow: none;
  }
}

.facetapi-terms span[class*=font-icon-select]:before,
.icon-dkan.facet-icon:before {
  font-family: 'DKAN-Topics';
  font-size: 24px;
  padding-right: .3em;
  vertical-align: middle;
}

.node-search-result {
  .icon-dkan.facet-icon:before {
    font-size: 42px;
    margin-top: 0.7em;
    display: block;
  }
  .resource-list li {
    display: inline-block;
  }
  .icon-container {
    text-align: center;
  }
}

.search-result {
  ul.resource-list {
    margin-top: 0.9em;

    li {
      display: inline-block;
      a,
      &:first-child {
        margin-left: 0px;
      }
    }
  }
  .dataset-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      font-size: 0.9em;
      font-style: italic;
      color: #999;
      margin-bottom: 0.5em;
      &:before {
        content: "\e97e";
        font-family: 'DKAN-Topics';
        font-style: normal;
        margin-right: .5em;
      }
    }
  }
  .group-membership {
    font-size: 0.9em;
    font-style: italic;
    color: #999;
    margin-bottom: 0.5em;
    &:before {
      content: "\e981";
      font-family: 'DKAN-Topics';
      font-style: normal;
      margin-right: .5em;
    }
  }
  &.search-result-group .group-membership {
    display: none;
  }
  p {
    margin-bottom: 0px;
  }
}

.node-search-result {
  h2.node-title {
    margin: 1.5em 0 .5em 0;
  }
}