// License block.
.pane-dkan-sitewide-dkan-sitewide-license .pane-content {
  word-break: break-word;
}

// OTHER ACCESS
.pane-dkan-sitewide-dkan-sitewide-other-access,
#block-dkan-sitewide-dkan-sitewide-other-access {
  a.btn {
    background-image: auto;
    border:1px solid $smoke;
    padding:6px 10px 6px 36px;
    position:relative;
    i {
      background-position: 0 0;
      position:absolute;
      top:8px;
      left:5px;
      visibility:visible;
      &.ckan-icon-rdfxml {
        background-image: url(../images/rdf.png);
      }
      &.ckan-icon-json {
        background-image: url(../images/json.png);
      }
    }
    &:hover {
      background-color:$gray-lighter;
    }
  }
  .btn-group {
    margin:.6em 0;
    .btn:first-child {
      background-image: url(../images/json.png);
      background-repeat:no-repeat;
      background-position: 10px 10px;
    }
    .btn:last-child {
      background-image: url(../images/rdf.png);
      background-repeat:no-repeat;
      background-position: 10px 10px;
    }
  }
}

// DKAN FORMS
#block-dkan-forms-dkan-forms-dataset-help h2.block-title::before{
  content: "\f05a";
  font-family: $icon-fa;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  font-size: 1.2em;
  line-height: 1;
  position: relative;
  top: 1px;
  margin-right: 0.4em;
}

// FACET Blocks
.panel-region-sidebar .radix-layouts-sidebar {
  .ctools-collapsible-container,
  div[class*="pane-facetapi"],
  .block-facetapi {
    &.pane-block,
    .pane-content {
      border: none;
      margin: 0;
      padding:0;
      @include border-radius(0);
    }
    .item-list {
      border: 1px solid $border;
      .facetapi-limit-link {
        padding: 10px 22px;
        &:before {
          top: 10px;
          right: 22px;
        }
      }
    }
    h2.pane-title {
      @include border-radius(0);
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      position: relative;
      padding: 9px 21px 9px 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:after {
        font-family: "fontawesome";
        content: "\f077";
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        right: 14px;
        top: 10px;
      }
    }
    &.ctools-collapsed {
      h2.pane-title:after {
        content: "\f078";
      }
    }
    .ctools-toggle {
      background: none;
    }
    ul {
      margin:0;
      padding:0;
    }
    ul li.leaf {
      list-style-type:none;
      list-style-image:none;
      margin:0;
      padding:0;
        li {
          position:relative;
        a {
          padding-right:48px;
        }
      }
      &.last {
        border-bottom:none;
      }
      li:before {
      content:"\f178";
      font-family:$icon-fa;
      position:absolute;
      top:4px;
      right:30px;
      }
      a {
        color:$gray-dark;
        display:block;
        padding:6px 20px 6px 10px;
        position:relative;
        line-height: 22px;
        &:focus,
        &:hover {
          color:darken($gray-dark, 10%);
          background: rgba(black, 0.03);
          text-decoration:none;
        }
        &:hover:after {
          content:"\f055";
          color:$brand-success;
          font-family:$icon-fa;
          position:absolute;
          top:6px;
          right:6px;
        }
        img {
          height: 25px;
          width: 25px;
          margin: 0 16px 0 0;
        }
      }
      a.facetapi-active {
        background:$smoke;
        color:#333;
        z-index:0;
      }
      a.facetapi-active:before {
        @include pointer;
      }
      a.facetapi-active:after {
        content:"\f00d";
        font-family:$icon-fa;
        position:absolute;
        top:6px;
        right:6px;
      }
      a.facetapi-active:hover:after {
        color:red;
      }
    }
    a.facetapi-limit-link {
      display:block;
      padding:5px 30px 5px 5px;
      position:relative;
    }
    a.facetapi-limit-link:before {
      content:"\f150";
      font-family:$icon-fa;
      position:absolute;
      top:6px;
      right:15px;
    }
    a.facetapi-limit-link.open:before {
      content:"\f151";
    }
  }
  .ctools-collapsible-container .ctools-toggle {
    width: 12px;
  }
}
// Date facets.
.facetapi-facet-changed {
  li.expanded {
    a.facetapi-active {
      background: #ccc;
      border-bottom: 1px dotted #eee;
      color: #333;
      line-height: 22px;
      padding: 6px 20px 6px 10px;
      &:hover {
        background: #ccc;
        color: #333;
        text-decoration: none;
        &:after {
          color: red;
        }
      }
      &:before {
        @include pointer;
      }
      &:after {
        content: "\f00d";
        font-family: "FontAwesome";
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
    ul.expanded li.leaf a,
    li.expanded a {
      padding: 6px 20px 6px 20px;
    }
    .item-list {
      border: none;
    }
  }
}

// Wrap long value strings.
.facetapi-facet-field-license a {
  @include wrap;
}

// Groups
.pane-views-group-block-block,
#block-views-group-block-block {
  overflow:hidden;
  .views-field-title a {
    display: block;
    padding: .5em 0;
  }
  .views-field-body p {
    margin: .5em 0 1em;
  }
  .group-subscribe-message {
    @include border-bottom-radius(4px);
    background: $brand-primary;
    color: #fff;
    padding:5px;
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
    a {
      color: #fff;
      display: block;
    }
  }
}

// Dataset page Group block.
.node-type-group .radix-bryant .radix-layouts-sidebar .pane-block.pane-views-group-block-block .pane-content {
  padding: 15px 15px 0;
}

#block-dkan-dataset-dkan-dataset-dataset-help {
  .content {
    padding: 10px;
  }
}

#block-dkan-forms-dkan-forms-dataset-help {
  h2:before {
    content: "\f05a";
    font-family: $icon-fa;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    font-size: 1.2em;
    line-height: 1;
    position: relative;
    top: 1px;
    margin-right: 0.4em;
  }
}

// Front page hero blocks.
.pane-dkan-sitewide-dkan-demo-front,
#block-dkan-sitewide-demo-front-dkan-demo-front {
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.5);
  //background-color: rgba($brand-primary, 0.5);
  p {
    color: #fff;
    padding: 15px;
  }
  .media-overlay {
    position: relative;
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .media-overlay .media-heading {
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    color: #FFFFFF;
    font-size: 13px;
    left: 0;
    line-height: 18px;
    margin: 0;
    padding: 12px 10px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
// Sidebar Social Links.
.radix-layouts-sidebar .pane-dkan-sitewide-dkan-sitewide-social.pane-block .pane-content {
  padding: 0;
  li.last a:hover {
    @include border-radius(0 0 3px 3px);
  }
}
// Additional Information block.
.pane-dkan-sitewide-dkan-sitewide-resource-add {
  table {
    margin-top: 0;
  }
}

// Rounded facet corners.
.panel-region-sidebar .radix-layouts-sidebar {
  .panel-panel-inner {
    .panels-ipe-portlet-wrapper:first-child,
    > div[class*="pane-facetapi"]:first-child {
      h2.pane-title {
        @include border-radius(5px 5px 0 0);
      }
    }
    .panels-ipe-portlet-wrapper:last-child,
    > div[class*="pane-facetapi"]:last-child {
      .ctools-collapsed,
      &.ctools-collapsed {
        h2.pane-title {
          @include border-radius(0 0 5px 5px);
        }
      }
    }
  }
}
